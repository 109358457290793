import { memo, useMemo } from 'react';
import cn from 'classnames';
import Image from 'next/legacy/image';

import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { Box } from 'uikit/Box';
import { Theme } from 'uikit/common/types';
import classes from './Error404.module.scss';

export const Error404 = memo(() => {
  const theme = useAppSelector(themeSelector);
  const starsIcon = useMemo(() => `/icons/stars_${theme}.png`, [theme]);
  const astronautIcon = useMemo(() => `/icons/astronaut_${theme}.png`, [theme]);
  return (
    <Box className={cn(classes.wrap, classes[theme])}>
      <Box className={classes.stars}>
        <Image
          src={starsIcon}
          alt="stars"
          layout="fill"
          objectFit="cover"
        />
      </Box>
      <Box className={classes.inner} justifyContent="space-between">
        <Box direction="column">
          <Box className={classes.text}>Page not found</Box>
          <Box className={classes.textStatus}>404</Box>
        </Box>
        <Box className={classes.iconWrap} direction="column" alignItems="center">
          {theme === Theme.light && (
            <Box className={classes.circle}>
              <Image
                src="/icons/circle_light.png"
                alt="circle"
                layout="fill"
                objectFit="cover"
              />
            </Box>
          )}
          <Box className={classes.astronaut}>
            <Image
              src={astronautIcon}
              alt="astronaut"
              layout="fill"
              objectFit="cover"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});