// format: 1,2,4,5,22,3,1
const getObjFromStringEnvVariable = (str?: string) => {
  return (str?.split(',') || []).reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {} as { [key: string]: boolean });
};

export interface Config<Prepared = any> {
  NEXT_PUBLIC_BFF_API: string | Prepared;
  NEXT_PUBLIC_BFF_WSS_API: string | Prepared;
  NEXT_PUBLIC_AUTH_API: string | Prepared;
  NEXT_PUBLIC_SP_PROVIDER_API: string | Prepared;
  NEXT_PUBLIC_CHECKCONFIDENTIALITY_API: string | Prepared;
  NEXT_PUBLIC_PCCS_API: string | Prepared;
  NEXT_PUBLIC_SHOW_EVENTS: boolean | Prepared;
  NEXT_PUBLIC_LIMIT_ORDERS_RUNNING: number | Prepared;
  NEXT_PUBLIC_DOCS: string | Prepared;
  NEXT_PUBLIC_SDK_VERSION: string | Prepared;
  NEXT_PUBLIC_SECRET_KEEPER_API: string | Prepared;
  // blockchain
  NEXT_PUBLIC_NETWORK_CHAIN_ID: number | Prepared;
  NEXT_PUBLIC_NETWORK_SCAN: string | Prepared;
  NEXT_PUBLIC_NETWORK_NAME: string | Prepared;
  NEXT_PUBLIC_BLOCKCHAIN_API: string | Prepared;
  NEXT_PUBLIC_BLOCKCHAIN_WSS_API: string | Prepared;
  NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS: string | Prepared;
  NEXT_PUBLIC_SP_GAS_PRICE: number | Prepared;
  // analytics
  NEXT_PUBLIC_ANALYTICS_API: string | Prepared;
  NEXT_PUBLIC_ANALYTICS_KEY: string | Prepared;
  // storj
  NEXT_PUBLIC_S3_API: string | Prepared;
  NEXT_PUBLIC_S3_REGION: string | Prepared;
  // development mode
  NEXT_PUBLIC_PROXY: { [key: string]: boolean };
  NEXT_PUBLIC_MOCK_HTTP: boolean | Prepared;
  NEXT_PUBLIC_DEBUG: boolean | Prepared;
  NEXT_PUBLIC_DISABLE_LOGGER: string | Prepared;
}

// null - for checking empty required fields
export const getPreparedConfig = (): Config<null> => ({
  NEXT_PUBLIC_BFF_API: process.env.NEXT_PUBLIC_BFF_API || null,
  NEXT_PUBLIC_BFF_WSS_API: process.env.NEXT_PUBLIC_BFF_WSS_API || null,
  NEXT_PUBLIC_AUTH_API: process.env.NEXT_PUBLIC_AUTH_API || null,
  NEXT_PUBLIC_SP_PROVIDER_API: process.env.NEXT_PUBLIC_SP_PROVIDER_API || null,
  NEXT_PUBLIC_CHECKCONFIDENTIALITY_API: process.env.NEXT_PUBLIC_CHECKCONFIDENTIALITY_API || null,
  NEXT_PUBLIC_PCCS_API: process.env.NEXT_PUBLIC_PCCS_API || null,
  NEXT_PUBLIC_SHOW_EVENTS: process.env.NEXT_PUBLIC_SHOW_EVENTS === undefined
    ? false
    : process.env.NEXT_PUBLIC_SHOW_EVENTS === 'true',
  NEXT_PUBLIC_LIMIT_ORDERS_RUNNING: process.env.NEXT_PUBLIC_LIMIT_ORDERS_RUNNING !== undefined
    ? +process.env.NEXT_PUBLIC_LIMIT_ORDERS_RUNNING
    : 2,
  NEXT_PUBLIC_DOCS: process.env.NEXT_PUBLIC_DOCS || 'https://docs.superprotocol.com',
  NEXT_PUBLIC_SDK_VERSION: process.env.NEXT_PUBLIC_SDK_VERSION || '',
  NEXT_PUBLIC_SECRET_KEEPER_API: process.env.NEXT_PUBLIC_SECRET_KEEPER_API || null,
  // analytics
  NEXT_PUBLIC_ANALYTICS_API: process.env.NEXT_PUBLIC_ANALYTICS_API || '',
  NEXT_PUBLIC_ANALYTICS_KEY: process.env.NEXT_PUBLIC_ANALYTICS_KEY || '',
  // blockchain
  NEXT_PUBLIC_NETWORK_CHAIN_ID: +(process.env.NEXT_PUBLIC_NETWORK_CHAIN_ID || 0) || 80001,
  NEXT_PUBLIC_NETWORK_SCAN: process.env.NEXT_PUBLIC_NETWORK_SCAN || null,
  NEXT_PUBLIC_NETWORK_NAME: process.env.NEXT_PUBLIC_NETWORK_NAME || null,
  NEXT_PUBLIC_BLOCKCHAIN_API: process.env.NEXT_PUBLIC_BLOCKCHAIN_API || null,
  NEXT_PUBLIC_BLOCKCHAIN_WSS_API: process.env.NEXT_PUBLIC_BLOCKCHAIN_WSS_API || null,
  NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS: process.env.NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS || null,
  NEXT_PUBLIC_SP_GAS_PRICE: +(process.env.NEXT_PUBLIC_SP_GAS_PRICE || '') || 60000000000,
  // storj
  NEXT_PUBLIC_S3_API: process.env.NEXT_PUBLIC_S3_API || 'https://gateway.storjshare.io',
  NEXT_PUBLIC_S3_REGION: process.env.NEXT_PUBLIC_S3_REGION || 'us-east-1',
  // development mode
  NEXT_PUBLIC_DEBUG: process.env.NEXT_PUBLIC_DEBUG === undefined
    ? true
    : process.env.NEXT_PUBLIC_DEBUG === 'true',
  NEXT_PUBLIC_DISABLE_LOGGER: process.env.NEXT_PUBLIC_DISABLE_LOGGER || '',
  NEXT_PUBLIC_PROXY: getObjFromStringEnvVariable(process.env.NEXT_PUBLIC_PROXY),
  NEXT_PUBLIC_MOCK_HTTP: process.env.NEXT_PUBLIC_MOCK_HTTP === undefined
    ? false
    : process.env.NEXT_PUBLIC_MOCK_HTTP === 'true',
});

// save in closure for better performance
const currentConfig = getPreparedConfig();

const getConfig = (): Config => currentConfig;

export default getConfig;